<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.slug"
            :error-messages="slugErrors"
            :label="$t('slug')"
            @input="$v.item.slug.$touch()"
            @blur="$v.item.slug.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('nom')"
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.parametreAffichage"
            :error-messages="parametreAffichageErrors"
            :label="$t('parametreAffichage')"
            @input="$v.item.parametreAffichage.$touch()"
            @blur="$v.item.parametreAffichage.$touch()"
          />
        </v-col>
      
        <v-col cols="12"></v-col>
      </v-row>
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'AnimationTypeForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
  },
  data() {
    return {
        slug: null,
        nom: null,
        parametreAffichage: null,
    };
  },
  computed: {

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, 'slug') && errors.push(this.violations.slug);


      return errors;
    },
    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, 'nom') && errors.push(this.violations.nom);


      return errors;
    },
    parametreAffichageErrors() {
      const errors = [];

      if (!this.$v.item.parametreAffichage.$dirty) return errors;

      has(this.violations, 'parametreAffichage') && errors.push(this.violations.parametreAffichage);


      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
  },
  validations: {
    item: {
      slug: {
      },
      nom: {
      },
      parametreAffichage: {
      },
    }
  }
};
</script>
